/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { Fragment } from "react";
import Layout from "../components/Layout";
import RichText from "../components/RichText";
import Separator from "../components/Separator";
import Accordion from "../components/Accordion";
import { DESKTOP_ARTICLE_MAX_WIDTH } from "../utils/Constants";

import { PlayToLearn } from "../assets/images/brailleheaders";

const LanguageCodes = {
  australia: "en-au",
  austria: "de-at",
  belgium: "fr-be",
  brazil: "pt-br",
  denmark: "da",
  finland: "fi",
  france: "fr-fr",
  germany: "de-de",
  holland: "nl-nl",
  ireland: "en-ie",
  italy: "it-it",
  "new zealand": "en-nz",
  norway: "no-no",
  portugal: "pt-pt",
  spain: "es-es",
  sweden: "sv-se",
  switzerland: "fr-ch",
  "united kingdom": "en-gb",
  "united states of america": "en-us",
};

const GetBricks = ({
  pageContext: {
    getBricksPage: { title, body, seo },
    distributionPartners,
  },
}) => (
  <Layout
    title={seo.title || title}
    description={seo.description}
    brailleTextImage={PlayToLearn}
  >
    <div sx={{ maxWidth: [null, null, `${DESKTOP_ARTICLE_MAX_WIDTH}px`] }}>
      <RichText render={body} />
    </div>
    <Themed.h2 sx={{ marginTop: "70px" }}>Official partners</Themed.h2>
    <Separator style={{ margin: 0 }} />
    {distributionPartners.map(
      ({ uid, country, name, phone, email, websiteUrl, socialMediaUrl }) => (
        <Fragment key={uid}>
          <Accordion
            label={country}
            renderChildren={isExpanded => {
              const tabIndex = isExpanded ? 0 : -1;

              return (
                <div aria-hidden={!isExpanded}>
                  <Themed.h3
                    sx={{
                      variant: "text.p",
                      fontWeight: "bold",
                      margin: "1em 0 0 0",
                    }}
                  >
                    <span
                      lang={LanguageCodes[country.toLowerCase()] || "en-US"}
                    >
                      {name}
                    </span>
                  </Themed.h3>
                  <Themed.p sx={{ marginTop: 0 }}>
                    {phone}
                    <br />
                    {email && (
                      <Themed.a
                        href={`mailto:${email.trim()}`}
                        tabIndex={tabIndex}
                      >
                        {email.trim()}
                      </Themed.a>
                    )}
                    <br />
                    {websiteUrl && (
                      <Themed.a href={websiteUrl.trim()} tabIndex={tabIndex}>
                        {websiteUrl.trim()}
                      </Themed.a>
                    )}
                    <br />
                    {socialMediaUrl && (
                      <Themed.a
                        href={socialMediaUrl.trim()}
                        tabIndex={tabIndex}
                      >
                        {socialMediaUrl.trim()}
                      </Themed.a>
                    )}
                  </Themed.p>
                </div>
              );
            }}
          />
          <Separator style={{ margin: 0 }} />
        </Fragment>
      )
    )}
  </Layout>
);

export default GetBricks;
